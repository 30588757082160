<template>
    <Slider :ratio="realRatio" :slides="[
        {
            image,
            title,
        },
    ]">
        <template v-slot:slide="{ slide }">
            <div class="c w-xxl flex hide__xs">
                <h2 v-if="slide.title"
                    class="m-0 pt-s pt-r__m-up pt-xl__l-up pl-l col-8 col-6__m-up col-4__l-up color-white">{{ slide.title
                    }}<span class="color-accent">.</span>
                </h2>
            </div>
        </template>
    </Slider>
</template>

<script>
export default {
    name: 'KaydenSlider',
    components: {
        Slider: () => import('@/components/ui/Slider.vue'),
    },
    props: {
        ratio: String,
        image: String,
        title: String,
    },
    computed: {
        realRatio() {
            if (this.ratio === 'small') {
                if (this.$store.getters.size('lte', 'm')) {
                    return '5by1';
                }
                return '10by1';
            }
            return this.ratio;
        },
    },
};
</script>
